<style lang="scss">.collapsible-body {
  padding: 1rem;
}</style>

<li>
  <div class="collapsible-header">
    <slot name="header" />
  </div>
  <div class="collapsible-body">
    <slot />
  </div>
</li>
