<style lang="scss">body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;
  padding: 0;
  background-color: #e0e0e0;
}

main {
  flex: 1 0 auto;
  margin: 20px;
}

.primary-color {
  color: #009b4a;
}

.error-toast {
  background-color: #f44336 !important;
}

button {
  float: right;
  background-color: #009b4a;
}
button:hover {
  background-color: #009b4a;
}</style>

<button class="btn-floating waves-effect waves-light" on:click>
  <slot />
</button>
