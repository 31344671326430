<style lang="scss">div {
  border-radius: 0 0 2px 2px;
  background-color: #fafafa;
  padding: 16px 24px;
  width: 100%;
  text-align: right;
  min-height: 55px;
  border-top: 1px solid rgba(160, 160, 160, 0.2);
  position: relative;
}</style>

<div>
  <slot />
</div>
